var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.trackLoading,
              expression: "trackLoading",
            },
          ],
          ref: "pageContent",
          staticClass: "page-content",
        },
        [
          _c("div", { staticClass: "content-header" }, [
            _c("p", { staticClass: "header-title" }, [
              _vm._v("候选人已存在于人才库，去对比补充后跟进"),
            ]),
            _c(
              "div",
              { staticClass: "option-button" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "edit-update-button",
                    attrs: { type: "text" },
                    on: { click: _vm.cancel },
                  },
                  [_vm._v("取消")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "edit-update-button",
                    attrs: { type: "text" },
                    on: { click: _vm.reset },
                  },
                  [_vm._v("重置")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { loading: _vm.trackLoading, type: "primary" },
                    on: { click: _vm.holdAndTrack },
                  },
                  [_vm._v("提交并跟进")]
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-row",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loadingCheck,
                  expression: "loadingCheck",
                },
              ],
              staticClass: "content-main",
            },
            [
              _c("candidate-raw-detail", {
                staticClass: "raw-detail-main pull-left",
                attrs: {
                  compareData: {
                    type: 0,
                    isFresher: !_vm.isFresher,
                    moreComplete: !_vm.moreComplete,
                  },
                  data: _vm.originalResumeData,
                  loading: _vm.originalResumeDataLoading,
                },
                on: { addToNewItem: _vm.addToNewItem },
              }),
              _c("candidate-raw-detail", {
                staticClass: "raw-detail-main pull-right",
                attrs: {
                  compareData: {
                    type: 1,
                    isFresher: _vm.isFresher,
                    moreComplete: _vm.moreComplete,
                  },
                  data: _vm.resumeDataUpload,
                  loading: _vm.resumeDataUploadLoading,
                },
                on: {
                  baseInfoEditCallback: _vm.baseInfoEditCallback,
                  deleteItem: _vm.deleteItem,
                  editItem: _vm.editItem,
                },
              }),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                "show-close": false,
                visible: _vm.updateTipDialogShow,
                "custom-class": "update-tip-dialog",
                title: "更新提醒",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.updateTipDialogShow = $event
                },
              },
            },
            [
              _c("div", {
                staticClass: "update-tip",
                domProps: { innerHTML: _vm._s(_vm.updateTip) },
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.hideUpdateTipDialog } }, [
                    _vm._v("放弃"),
                  ]),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.hideUpdateTipDialog },
                    },
                    [_vm._v("去编辑补充")]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("fllow-resume-dialog", {
        ref: "fllowResumeDialog",
        attrs: { from: "coc-update" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }