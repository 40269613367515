<template>
    <div class="container">
        <div
            class="page-content"
            ref="pageContent"
            v-loading="trackLoading"
        >
            <div class="content-header">
                <!-- <p class="header-title">{{checkTitle[duplicateType]}}</p> -->
                <p class="header-title">候选人已存在于人才库，去对比补充后跟进</p>
                <div class="option-button">
                    <el-button
                        @click="cancel"
                        class="edit-update-button"
                        type="text"
                    >取消</el-button>
                    <el-button
                        @click="reset"
                        class="edit-update-button"
                        type="text"
                    >重置</el-button>
                    <!-- class="confirm-update-button" -->
                    <el-button
                        :loading="trackLoading"
                        @click="holdAndTrack"
                        type="primary"
                    >提交并跟进</el-button>
                    <!-- <el-button type="text" class="edit-update-button" @click="continueEdit">继续编辑</el-button> -->
                    <!-- <el-button type="primary" @click="continueEdit">去编辑补充</el-button>

                    <el-button 
                        type="primary" 
                        class="pull-right confirm-update-button" 
                        @click="beforeUpdateRecume"
                        >确认更新该简历
                    </el-button>
                    <el-button type="info" class="pull-right" @click="giveUpEdit">放弃</el-button>-->
                </div>
            </div>

            <el-row
                class="content-main"
                v-loading="loadingCheck"
            >
                <!-- v-if="originalResumeData"  v-if="resumeDataUpload"-->
                <!-- :compareData="{ type: 0, isFresher: !isFresher, moreComplete: !moreComplete }" -->
                <candidate-raw-detail
                    :compareData="{ type: 0, isFresher: !isFresher, moreComplete: !moreComplete }"
                    :data="originalResumeData"
                    :loading="originalResumeDataLoading"
                    @addToNewItem="addToNewItem"
                    class="raw-detail-main pull-left"
                ></candidate-raw-detail>
                <!-- :compareData="{ type: 1, isFresher, moreComplete }" -->
                <candidate-raw-detail
                    :compareData="{ type: 1, isFresher, moreComplete }"
                    :data="resumeDataUpload"
                    :loading="resumeDataUploadLoading"
                    @baseInfoEditCallback="baseInfoEditCallback"
                    @deleteItem="deleteItem"
                    @editItem="editItem"
                    class="raw-detail-main pull-right"
                ></candidate-raw-detail>
            </el-row>

            <el-dialog
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :show-close="false"
                :visible.sync="updateTipDialogShow"
                custom-class="update-tip-dialog"
                title="更新提醒"
            >
                <div
                    class="update-tip"
                    v-html="updateTip"
                ></div>
                <span
                    class="dialog-footer"
                    slot="footer"
                >
                    <el-button @click="hideUpdateTipDialog">放弃</el-button>
                    <!-- @click="continueEdit(true)" -->
                    <el-button
                        @click="hideUpdateTipDialog"
                        type="primary"
                    >去编辑补充</el-button>
                </span>
            </el-dialog>
        </div>
        <fllow-resume-dialog
            from="coc-update"
            ref="fllowResumeDialog"
        />
    </div>
</template>

<script>
import candidateRawDetail from './candidateRawDetail.vue';
import PublicJobService from '#/js/service/publicJobService.js';
import CandidateService from '#/js/service/candidateService.js';
import FllowResumeDialog from '../corporation-opportunity/component/fllow_resume_dialog.vue';
// const tipsMap = {
//     workExperiences: '工作经历',
//     educationExperiences: '教育经历',
//     projectExperiences: '项目经验',
//     selfEvaluation: '自我评价',
//     intentions: '期望工作',
// };
const fieldMap = {
    realName: "姓名",
    gender: "性别",
    mobile: "手机",
    email: "邮箱",
    wechat: "微信",
    city: "城市",
    yearOfExperience: "工作年限",
    company: "公司",
    title: "职位",
    school: "学校",
    degree: "学历",
    major: "专业",
    workExperiences: "工作经历",
    educationExperiences: "教育经历",
    projectExperiences: "项目经验",
    projectName: '项目名称',
    selfEvaluation: "自我评价",
    intentions: "期望工作"
};
export default {
    name: 'CocUpdate',
    components: {
        candidateRawDetail,
        FllowResumeDialog,
    },
    data () {
        return {
            fileName: "",
            resumeId: "",
            originalResumeId: "",
            checkTitle: [
                '简历已存在，你可以覆盖一份更新、更完整的简历！',
                '简历已存在，你的上传的这份还不够新哦~',
                '简历已存在，你的上传的这份还不够完整哦~',
                '简历已存在，不能进行覆盖'
            ],

            isFresher: false,
            moreComplete: false,
            extensionMode: false,
            updateTipDialogShow: false,
            updateTip: "",
            uncompleteArr: [],
            requiredArr: [],
            isXLSEdit: false,
            loadingCheck: false,

            resumeDataUpload: {},
            resumeDataUploadLoading: false,
            originalResumeData: {},
            originalResumeDataLoading: false,

            cacheResumeDataUpload: '', // 缓存的大厂简历数据，为了重置
            trackLoading: false,
            saveBtnClick: false,

        };
    },
    created () {
        this.extensionMode = location.href.indexOf('file=') > -1 ? true : false;
    },
    mounted () {
        this.fileName = encodeURI(decodeURI(this.$route.query.file));
        // this.init();
        console.log(`query:`, this.$route.query);


        this.getResumeDeatil();
        this.getCandidateDetail();
    },
    computed: {
        duplicateType () {
            if (this.isFresher && this.moreComplete) {
                return 0;
            } else if (this.moreComplete) {
                return 1;
            } else if (this.isFresher) {
                return 2;
            } else {
                return 3;
            }
        },
    },
    methods: {
        // 大厂简历详情
        getResumeDeatil () {
            this.originalResumeDataLoading = true;
            PublicJobService
                .getDeliverResumeDetail(this.$route.query.deliveryId)
                .then(res => {
                    this.resumeDataUpload = res || {};
                    this.cacheResumeDataUpload = JSON.stringify(res);
                    this.init();
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {
                    this.originalResumeDataLoading = false;
                });
        },

        // 原简历详情
        getCandidateDetail () {
            this.resumeDataUploadLoading = true;
            CandidateService
                .candidateSimpleResumeDetail(this.$route.query.resumeId)
                .then(res => {
                    this.originalResumeData = res || {};
                })
                .catch((e) => {
                    console.log(e);
                })
                .finally(() => {
                    this.resumeDataUploadLoading = false;
                });
        },

        //////////////////////////////////////////
        init () {
            // this.originalResumeId = this.$route.query.originalResumeId;
            this.originalResumeId = this.$route.query.resumeId;
            // this.isXlsAnonymous = this.$route.query.isAnonymous === 'true' ? true : false;
            this.isExcelUpload = this.$route.query.isExcelUpload == '1' ? true : false;
            this.isXLSEdit = encodeURI(decodeURI(this.$route.query.file)).indexOf('xlsEditFile') > -1 ? true : false;
            if (!this.extensionMode) {
                this.CompareResume();
            } else {
                this.handleUploadData();
            }
        },
        handleUploadData () {
            //是否为补充简历
            // this.fileName = encodeURI(decodeURI(getUrlParam('file')));
            this.fileName = encodeURI(decodeURI(this.$route.query.file));
            if (this.fileName !== '') {
                let fileData = JSON.parse(sessionStorage.getItem(this.fileName) || sessionStorage.getItem(encodeURIComponent(this.fileName)));
                if (fileData.workExperiences && fileData.workExperiences.length) {
                    fileData.company = fileData.company ? fileData.company : fileData.workExperiences[0].company;
                    fileData.title = fileData.title ? fileData.title : fileData.workExperiences[0].title;
                }
                if (fileData.educationExperiences && fileData.educationExperiences.length) {
                    fileData.school = fileData.school ? fileData.school : fileData.educationExperiences[0].school;
                    fileData.degree = fileData.degree && fileData.degree !== '0' ? fileData.degree : fileData.educationExperiences[0].degree;
                    fileData.major = fileData.major ? fileData.major : fileData.educationExperiences[0].major;
                } else {
                    if (!this.isExcelUpload) {
                        fileData.school = "";
                        fileData.degree = null;
                        fileData.major = "";
                    }
                }
                this.$set(this, 'resumeDataUpload', fileData);
                if (this.extensionMode) {
                    this.CompareResume();
                }
            }
        },

        CompareResume () {
            this.loadingCheck = true;
            // if (this.extensionMode) {
                const params = {
                    ...this.resumeDataUpload,
                    // ...this.originalResumeData,
                    resumeId: this.originalResumeId
                };
                _request({
                    method: 'POST',
                    url: `/My/Candidate/CompareResume`,
                    data: params,
                })
                    .then((r) => {
                        this.resumeDataUpload = r.createResume;
                        this.originalResumeData = r.editResume;
                        this.moreComplete = r.createResumeIsMoreComplete;
                        this.isFresher = r.createResumeIsFresher;
                        this.uncompleteArr = r.differentFields || [];
                        this.requiredArr = r.requiredFields || [];

                        this.getCompareResult();
                    }).finally(() => {
                        this.loadingCheck = false;
                    });
            // } else {
            //     // this.resumeId = getUrlParam('resumeId');
            //     // request(`/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`, {}, 'get', true, true)

            //     this.resumeId = this.$route.query.resumeId;
            //     _request({
            //         method: 'GET',
            //         url: `/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`
            //     })
            //         .then((r) => {
            //             this.resumeDataUpload = r.createResume;
            //             this.originalResumeData = r.editResume;
            //             this.moreComplete = r.createResumeIsMoreComplete;
            //             this.isFresher = r.createResumeIsFresher;
            //             this.uncompleteArr = r.differentFields || [];
            //             this.requiredArr = r.requiredFields || [];

            //             this.getCompareResult();
            //         }).finally(() => {
            //             this.loadingCheck = false;
            //         });
            // }
        },

        getCompareResult () {
            //判断是否解析出必填项
            if (!this.resumeDataUpload.company) {
                this.$set(this.resumeDataUpload, 'company', this.resumeDataUpload.workExperiences[0].company);
                this.$set(this.resumeDataUpload.workExperiences[0], 'isLatestWorkExperience', true);
            }
            if (!this.resumeDataUpload.title) {
                this.$set(this.resumeDataUpload, 'title', this.resumeDataUpload.workExperiences[0].title);
            }
            // if(this.resumeDataUpload.yearOfExperience>=10){
            //     this.$set(this.resumeDataUpload,'yearOfExperience',10);
            // }
            this.$set(this.resumeDataUpload, 'isXLSEdit', this.isXLSEdit);

            if(this.saveBtnClick) {
                this.beforeUpdateRecume2();
            }
        },

        continueEdit (isUncompleteTip) {
            // ga('send', 'event', "猎头端-更新简历", "继续编辑", `${userID}-${userFirmShortName}-${userRealName}`);
            let resumeData = Object.assign({}, this.resumeDataUpload);
            let resumeBasicInformation = {
                "isExisted": false,
                "existedId": null,
                "realName": "",
                "gender": 0,
                "mobile": "",
                "title": "",
                "email": "",
                "wechat": "",
                "source": null,
                "company": "",
                "yearOfExperience": 0,
                "province": "",
                "city": null,
                "content": "",
                "extraInfo": null,
                "jobHoppingOccasion": null,
                "employerInfo": null,
                "expectSalary": null,
                "school": "",
                "major": "",
                "degree": null,
                "fileId": "",
                "autoApprovelTrading": false,
                "selfEvaluation": '',
                "workExperiences": [],
                "educationExperiences": [],
                "projectExperiences": [],
                "intentions": [],
                "isPersonalCreate": false
            };
            Object.assign(resumeBasicInformation, resumeData);
            if (!resumeBasicInformation.workExperiences || (resumeBasicInformation.workExperiences && resumeBasicInformation.workExperiences.length == 0)) {
                resumeBasicInformation.workExperiences = [];
                resumeBasicInformation.workExperiences.push({
                    "company": resumeData.company || "",
                    "title": resumeData.title || "",
                    "description": null,
                    "isLatestWorkExperience": true,
                    "location": 0,
                    "startDate": null,
                    "endDate": null,
                    "created": "",
                    "updated": "",
                    //"showPopover": false,
                    "locationArr": [],
                    "startDateError": false,
                    "endDateError": false,
                    "dateRange": []
                });
            }
            if (!resumeBasicInformation.educationExperiences || (resumeBasicInformation.educationExperiences && resumeBasicInformation.educationExperiences.length == 0)) {
                resumeBasicInformation.educationExperiences = [];
                resumeBasicInformation.educationExperiences.push({
                    "school": resumeData.school || "",
                    "major": "",
                    "degree": resumeData.degree || null,
                    "startDate": null,
                    "endDate": null,
                    "created": "",
                    "updated": "",
                    //"showPopover": false,
                    "startDateError": false,
                    "endDateError": false,
                    "dateRange": []
                });
            }
            if (!this.fileName) {
                this.fileName = this.isXLSEdit ? 'xlsEditFile' : 'singleEditFile';
            }
            if (this.duplicateType === 2 && this.uncompleteArr.length > 0 && isUncompleteTip) {
                resumeBasicInformation.unCompleteFields = this.uncompleteArr;
                if (this.uncompleteArr.indexOf('degree') > -1) {
                    resumeBasicInformation.educationExperiences.forEach(item => {
                        if (!item.degree) {
                            item.degree = null;
                        }
                    });
                }
            }
            sessionStorage.setItem(this.fileName, JSON.stringify(resumeBasicInformation));
            // location.href = `/Headhunting/MyCompany.html?type=1&file=${this.fileName}#/resumeUpload/singleUpload`;
            location.href = `/?type=1&file=${this.fileName}#/resumeUpload/singleUpload`;
        },

        giveUpEdit () {
            // ga('send', 'event', "猎头端-更新简历", "放弃更新", `${userID}-${userFirmShortName}-${userRealName}`);
            window.close();
        },

        beforeUpdateRecume () {
            if (this.duplicateType === 2 && this.uncompleteArr.length > 0) {
                let uncompleteTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.uncompleteArr.length; i++) {
                    toLowerCaseTxt = this.uncompleteArr[i].charAt(0).toLowerCase() + this.uncompleteArr[i].slice(1);
                    this.uncompleteArr[i] = toLowerCaseTxt;
                    if (fieldMap[toLowerCaseTxt]) {
                        uncompleteTextArr.push(fieldMap[toLowerCaseTxt]);
                    }
                }
                this.updateTip = `你上传的简历，对比原简历，缺失字段：<span class="text-orange">${uncompleteTextArr.join("、")}</span><br/>请补充完整后再更新。`;
                this.updateTipDialogShow = true;
            } else if ((this.duplicateType === 0 || this.duplicateType === 2) && this.requiredArr.length > 0) {
                let requiredTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.requiredArr.length; i++) {
                    toLowerCaseTxt = this.requiredArr[i].charAt(0).toLowerCase() + this.requiredArr[i].slice(1);
                    this.requiredArr[i] = toLowerCaseTxt;
                    if (fieldMap[toLowerCaseTxt]) {
                        requiredTextArr.push(fieldMap[toLowerCaseTxt]);
                    }
                }
                this.updateTip = `你上传的简历，缺失必填项：<span class="text-orange">${requiredTextArr.join("、")}</span><br/>请补充后再更新。`;
                this.updateTipDialogShow = true;
            } else if (this.duplicateType === 2) {
                this.$confirm('你上传的简历没有原简历完整，确定直接更新吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '放 弃',
                    type: 'warning',
                    center: false
                }).then(({ value }) => {
                    this.confirmAndSubmit();
                }).catch(() => {
                    this.hideUpdateTipDialog();
                });
            } else {
                this.confirmAndSubmit();
            }
        },
        confirmAndSubmit () {
            // let loadingInstance = Loading.service({
            //     target: this.$refs.pageContent,
            //     customClass: 'page-loading'
            // });
            this.loadingCheck = true;
            let _self = this;
            this.$set(this.resumeDataUpload, 'id', this.originalResumeData.id);
            this.$set(this.resumeDataUpload, 'isUploadResume', true);
            // request('/My/Candidate/' + this.originalResumeData.id + '/Edit', this.resumeDataUpload, 'post', true, true)
            _request({
                method: 'POST',
                url: '/My/Candidate/' + this.originalResumeData.id + '/Edit',
                data: this.resumeDataUpload,
            })
                .then((r) => {
                    if (_self.duplicateType === 2 && !_self.uncompleteArr.length) {
                        let today = new Date();
                        let month = today.getMonth() + 1,
                            date = today.getDate(),
                            hour = today.getHours(),
                            minutes = today.getMinutes(),
                            seconds = today.getSeconds();
                        month = month > 9 ? month : '0' + month;
                        date = date > 9 ? date : '0' + date;
                        hour = hour > 9 ? hour : '0' + hour;
                        minutes = minutes > 9 ? minutes : '0' + minutes;
                        seconds = seconds > 9 ? seconds : '0' + seconds;
                        let timeText = `${today.getFullYear()}/${month}/${date} ${hour}:${minutes}:${seconds}`;
                        this.upaRequest(timeText, () => {
                            shortTips('更新成功！');
                            setTimeout(() => {
                                // location.href = '/Headhunting/MyCompany.html#/candidateDetail/' + this.originalResumeData.id;
                                location.href = '/#/candidateDetail/' + this.originalResumeData.id;
                            }, 1000);
                            // ga('send', 'event', "猎头端-更新简历", "简历覆盖", `${userID}-${userFirmShortName}-${userRealName}`);
                        });
                    } else {
                        shortTips('更新成功！');
                        setTimeout(() => {
                            // location.href = '/Headhunting/MyCompany.html#/candidateDetail/' + this.originalResumeData.id;
                            location.href = '/#/candidateDetail/' + this.originalResumeData.id;
                        }, 1000);
                        // ga('send', 'event', "猎头端-更新简历", "简历覆盖", `${userID}-${userFirmShortName}-${userRealName}`);
                    }
                }).finally(() => {
                    this.loadingCheck = false;
                });
        },
        hideUpdateTipDialog () {
            this.updateTipDialogShow = false;
        },
        upaRequest (timeText, cb) {
            const params = {
                eventCode: 'UpdateUnCompleteResume',
                eventData: JSON.stringify({
                    id: this.originalResumeData.id,
                    updateTime: timeText
                }),
                pageCode: location.pathname,
                pageViewId: $('[name=pageViewId]').val() || window._pageViewId || ""
            };
            _request({
                method: 'POST',
                url: '/UPA/events',
                data: params,
            }).then((r) => {
                cb && cb();
            });
        },

        //-----------------------------------
        addToNewItem (item, type) {
            switch (type) {
                case 'workExperiences':
                    if(this.resumeDataUpload.workExperiences) {
                        this.resumeDataUpload.workExperiences.unshift(item);
                    } else {
                        this.resumeDataUpload.workExperiences = [item];
                    }
                    break;
                case 'educationExperiences':
                    if(this.resumeDataUpload.educationExperiences) {
                        this.resumeDataUpload.educationExperiences.unshift(item);
                    } else {
                        this.resumeDataUpload.educationExperiences = [item];
                    }
                    break;
                case 'projectExperiences':
                    if(this.resumeDataUpload.projectExperiences) {
                        this.resumeDataUpload.projectExperiences.unshift(item);
                    } else {
                        this.resumeDataUpload.projectExperiences = [item];
                    }
                    break;
                case 'selfEvaluation':
                    this.resumeDataUpload.selfEvaluation = item;
                    break;
                case 'intentions':
                    if(this.resumeDataUpload.intentions) {
                        this.resumeDataUpload.intentions.unshift(item);
                    } else {
                        this.resumeDataUpload.intentions = [item];
                    }
                    break;
                default:
                    break;
            }
            // shortTips(`原简历成功添加到大厂简历！`);
            // intentions
            // workExperiences
            // educationExperiences
            // projectExperiences
        },
        deleteItem (index, type) {
            switch (type) {
                case 'workExperiences':
                    this.resumeDataUpload.workExperiences.splice(index, 1);
                    break;
                case 'educationExperiences':
                    this.resumeDataUpload.educationExperiences.splice(index, 1);
                    break;
                case 'projectExperiences':
                    this.resumeDataUpload.projectExperiences.splice(index, 1);
                    break;
                case 'selfEvaluation':
                    this.resumeDataUpload.selfEvaluation = '';
                    break;
                case 'intentions':
                    this.resumeDataUpload.intentions.splice(index, 1);
                    break;
                default:
                    break;
            }
            // shortTips(`内容大厂简历中删除成功！`);
        },
        editItem (val, idx, type) {
            console.log(`argggg:`, val, idx, type);
            switch (type) {
                case 'workExperiences':
                    if (idx == 10000) { //10000为增加
                        if (this.resumeDataUpload.workExperiences) {
                            this.resumeDataUpload.workExperiences.unshift(val);
                        } else {
                            this.resumeDataUpload.workExperiences = [val];
                        }
                    } else {
                        this.resumeDataUpload.workExperiences.splice(idx, 1, val);
                    }
                    break;
                case 'educationExperiences':
                    if (idx == 10000) { //10000为增加
                        if (this.resumeDataUpload.educationExperiences) {
                            this.resumeDataUpload.educationExperiences.unshift(val);
                        } else {
                            this.resumeDataUpload.educationExperiences = [val];
                        }
                    } else {
                        this.resumeDataUpload.educationExperiences.splice(idx, 1, val);
                    }
                    break;
                case 'projectExperiences':
                    if (idx == 10000) { //10000为增加
                        if (this.resumeDataUpload.projectExperiences) {
                            this.resumeDataUpload.projectExperiences.unshift(val);
                        } else {
                            this.resumeDataUpload.projectExperiences = [val];
                        }
                    } else {
                        this.resumeDataUpload.projectExperiences.splice(idx, 1, val);
                    }
                    break;
                case 'selfEvaluation':
                    this.resumeDataUpload.selfEvaluation = val;
                    break;
                case 'intentions':
                    if (idx == 10000) { //10000为增加
                        if (this.resumeDataUpload.intentions) {
                            this.resumeDataUpload.intentions.unshift(val);
                        } else {
                            this.resumeDataUpload.intentions = [val];
                        }
                    } else {
                        this.resumeDataUpload.intentions.splice(idx, 1, val);
                    }
                    break;
                default:
                    break;
            }

            // shortTips(`内容成功添加至大厂简历`);
        },

        cancel () {
            // 跳回去
            // window.close();
            location.href = `/#/corporationOpportunity?activeTabName=deliveryManage`;
        },

        reset () {
            this.$confirm('重置后会丢失已编辑，是否继续？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
            })
                .then(() => {
                    this.resumeDataUpload = JSON.parse(this.cacheResumeDataUpload);
                    shortTips(`数据已重置！`);
                })
                .catch(() => { });
        },
        holdValidate () {
            return new Promise((resolve, reject) => {
                console.log(`o---------------------o`);
                console.log(this.resumeDataUpload);
                if (this.resumeDataUpload.workExperiences?.length == 0) {
                    reject({ type: 'validateError', text: '工作经验不能为空' });
                }
                if (this.resumeDataUpload.educationExperiences?.length == 0) {
                    reject({ type: 'validateError', text: '教育经历不能为空' });
                }
                // if (this.resumeDataUpload.intentions.length == 0) {
                //     reject({ type: 'validateError', text: '求职意向不能为空' });
                // }
                // if (this.resumeDataUpload.selfEvaluation.trim() == '') {
                //     reject({ type: 'validateError', text: '自我评价不能为空' });
                // }
                // if(!this.resumeDataUpload.email) {
                //     reject({ type: 'validateError', text: '邮箱不能为空' });
                // }
                // let emailReg = /(^\s*[a-zA-Z0-9_%+#&'*/=^`{|}~-](?:\.?[a-zA-Z0-9_%+#&'*/=^`{|}~-])*@(?:[a-zA-Z0-9_](?:(?:\.?|-*)[a-zA-Z0-9_])*\.[a-zA-Z]{2,9}|\[(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)\.(?:2[0-4]\d|25[0-5]|[01]?\d\d?)])\s*$)|(^(1[3-9])\d{9}$)/;
                // if(!emailReg.test(this.resumeDataUpload.email)) {
                //     reject({ type: 'validateError', text: '请输入正确的电子邮箱' });
                // }
                
                resolve(true);
            });
        },
        async holdAndTrack () {
            try {
                this.saveBtnClick = true;
                await this.holdValidate();
                this.CompareResume2();
            } catch (e) {
                if (e.type == 'validateError') {
                    shortTips(e.text);
                }
            }
        },
        submitSave() {
            this.trackLoading = true;
            let resumeData = {
                ...this.resumeDataUpload,
                publicJobDeliveryId: this.$route.query.deliveryId,
                id: this.$route.query.candidatId,
            };
            PublicJobService
                .cocResumeSave(this.$route.query.candidatId, resumeData)
                .then(res => {
                    shortTips(`简历保存成功！`);
                    this.$refs['fllowResumeDialog'].show({
                        jobId: this.$route.query.jobId,
                        id: this.$route.query.deliveryId,
                    }, this.$route.query.candidatId);
                })
                .finally(() => {
                    this.trackLoading = false;
                });
        },
        baseInfoEditCallback (data) {
            console.log(`data:`, data)
            Object.assign(this.resumeDataUpload, data);
            console.log(this.resumeDataUpload)
            // shortTips(`大厂基本信息编辑成功！`);
        },

        // 调对比接口判断简历能不能修改
        CompareResume2(){
            this.saveLoading = true;
            // if(this.extensionMode) {
                const params = {
                    ...this.resumeDataUpload,
                    // ...this.originalResumeData,
                    resumeId: this.originalResumeId
                };
                _request({
                    method: 'POST',
                    url: `/My/Candidate/CompareResume`,
                    data: params,
                })
                .then((r) =>{
                    this.resumeDataUpload = r.createResume;
                    this.originalResumeData = r.editResume;
                    this.moreComplete = r.createResumeIsMoreComplete;
                    this.isFresher = r.createResumeIsFresher;
                    this.uncompleteArr = r.differentFields || [];
                    this.requiredArr = r.requiredFields || [];

                    this.getCompareResult();
                }).finally(() => {
                    this.saveLoading = false;
                })
            // } else {
            //     // this.resumeId = getUrlParam('resumeId');
            //     // request(`/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`, {}, 'get', true, true)

            //     this.resumeId = this.$route.query.resumeId;
            //     _request({
            //         method: 'GET',
            //         url: `/My/Candidate/${this.resumeId}/CompareTo/${this.originalResumeId}`
            //     })
            //     .then((r) => {
            //         this.resumeDataUpload = r.createResume;
            //         this.originalResumeData = r.editResume;
            //         this.moreComplete = r.createResumeIsMoreComplete;
            //         this.isFresher = r.createResumeIsFresher;
            //         this.uncompleteArr = r.differentFields || [];
            //         this.requiredArr = r.requiredFields || [];
                   
            //         this.getCompareResult();
            //     }).finally(() => {
            //         this.saveLoading = false;
            //     })
            // }
        },

        beforeUpdateRecume2() {
            if(this.duplicateType === 2 && this.uncompleteArr.length > 0) {
                let uncompleteTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.uncompleteArr.length; i++) {
                    toLowerCaseTxt = this.uncompleteArr[i].charAt(0).toLowerCase() + this.uncompleteArr[i].slice(1);
                    this.uncompleteArr[i] = toLowerCaseTxt;
                    if(fieldMap[toLowerCaseTxt]) {
                        uncompleteTextArr.push(fieldMap[toLowerCaseTxt])
                    }
                }
                this.updateTip = `你上传的简历，对比原简历，缺失字段：<span class="text-orange">${uncompleteTextArr.join("、")}</span><br/>请补充完整后再更新。`
                this.updateTipDialogShow = true;
            } else if((this.duplicateType === 0 || this.duplicateType === 2 ) && this.requiredArr.length > 0) {
                let requiredTextArr = [],
                    toLowerCaseTxt = "";
                for (let i = 0; i < this.requiredArr.length; i++) {
                    toLowerCaseTxt = this.requiredArr[i].charAt(0).toLowerCase() + this.requiredArr[i].slice(1);
                    this.requiredArr[i] = toLowerCaseTxt;
                    if(fieldMap[toLowerCaseTxt]) {
                        requiredTextArr.push(fieldMap[toLowerCaseTxt])
                    }
                }
                this.updateTip = `你上传的简历，缺失必填项：<span class="text-orange">${requiredTextArr.join("、")}</span><br/>请补充后再更新。`
                this.updateTipDialogShow = true;
            } else if(this.duplicateType === 2) {
                this.$confirm('你上传的简历没有原简历完整，确定直接更新吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '放 弃',
                    type: 'warning',
                    center: false
                }).then(({ value }) => {
                    // this.confirmAndSubmit();
                    this.submitSave();
                }).catch(() => {
                    this.hideUpdateTipDialog();       
                });
            } else {
                // this.confirmAndSubmit();
                if(this.duplicateType === 0) {
                    this.submitSave();
                }else if(this.duplicateType === 3) {
                    shortTips(`简历已存在，不能进行覆盖`);
                } else {
                    shortTips(`简历非最新鲜，不能进行覆盖`);
                }
            }
        },
    }
}
</script>

<style lang="scss">
.container {
    .page-content {
        .el-dialog.update-tip-dialog {
            width: 480px;
        }
    }
}
</style>
<style lang="scss" scoped>
.container {
    height: 100%;
    padding: 20px;
    overflow-y: auto;
    // background: #f6f6f6;
}
.page-content {
    .content-header {
        margin-bottom: 20px;
        height: 100px;
        background-color: #fff;
        border-radius: 8px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .header-title {
            font-size: 18px;
            color: #666666;
        }
        .el-button--text {
            width: 80px;
            height: 28px;
            &.edit-update-button {
                border-color: #dddddd;
            }
        }
        .el-button--primary {
            width: 150px;
            border-radius: 4px;
            // padding: 8px 25px;
            &.confirm-update-button {
                background-color: #38bc9d;
                border-color: #38bc9d;
                height: 28px;
                margin-left: 20px;
                &:hover {
                    background-color: #56dabb;
                    border-color: #56dabb;
                }
            }
        }
        .el-button--info {
            width: 80px;
            border-radius: 2px;
            // padding: 8px 25px;
        }
    }
    .content-main {
        display: flex;
        justify-content: space-between;
        .raw-detail-main {
            // width: 480px;
            flex: 1;
            background: #ffffff;
            border-radius: 8px;
            &.pull-left {
                margin-right: 20px;
            }
        }
    }
}

.el-dialog.update-tip-dialog {
    width: 480px !important;
    .el-dialog__title {
        font-weight: normal;
    }
    .update-tip {
        color: #444;
        line-height: 28px;
        .text-orange {
            color: #fa765d;
        }
    }
    .el-dialog__footer {
        padding: 0 20px 20px;
        .el-button {
            min-width: 0px;
            width: 84px;
            height: 32px;
            line-height: 32px;
            padding: 0;
            border-radius: 2px;
        }
        .el-button + .el-button {
            margin-left: 10px;
        }
    }
}
.el-message-box.update-confirm-dialog {
    width: 480px;
    height: 180px;
    .el-message-box__header {
        display: none;
    }
    .el-message-box__content {
        font-size: 16px;
        color: #444;
        line-height: 24px;
        padding: 40px 20px;
    }
    .el-message-box__btns {
        text-align: center;
    }
}
.el-loading-mask.page-loading {
    .el-loading-spinner {
        top: 300px;
    }
}
</style>